.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #e27c00;
}
.button:active {
  background-color: #e27c00;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #e27c00;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: "proxima-nova", sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #e27c00;
  color: #fff;
}
.wrapper .flag {
  background: unset;
  color: unset;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 1199px) {
  .unit {
    margin-top: 15px;
  }
}
@media (max-width: 639px) {
  .unit {
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .unit {
    margin-bottom: 15px;
  }
}
@media (max-width: 639px) {
  .unit {
    margin-bottom: 10px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}
@media (max-width: 1199px) {
  .part {
    margin-top: 15px;
  }
}
@media (max-width: 639px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .part {
    margin-bottom: 15px;
  }
}
@media (max-width: 639px) {
  .part {
    margin-bottom: 10px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  position: relative;
  z-index: 3;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: block;
  text-align: center;
  color: #FFF;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  font-family: 'FrutigerBlack', sans-serif;
  display: inline-block;
  position: relative;
}
div.load a.load:before {
  content: '> ';
}
div.load a.load:after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
div.load a.load:hover,
div.load a.load:focus {
  color: #000;
}
div.load a.load:hover:after,
div.load a.load:focus:after {
  width: 100%;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 20px;
  list-style-position: outside;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 51.953125%;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1920px) {
  #slides {
    padding-bottom: calc(100vh - 85px);
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.onload-animation #slides img {
  animation-name: zoomIn;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 5%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-left-black.svg);
}
#expo a.link.prev:hover,
#expo a.link.prev:focus {
  background-image: url(/images/icon-arrow-left-orange.svg);
}
#expo a.link.next {
  right: 5%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-right-black.svg);
}
#expo a.link.next:hover,
#expo a.link.next:focus {
  background-image: url(/images/icon-arrow-right-orange.svg);
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: none;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: none;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #e27c00;
}
.cb-index-some {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: "proxima-nova", sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 639px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 639px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 639px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #e27c00;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #e27c00;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  margin: 10px 0 10px 20px;
  font-family: 'FrutigerBlack', sans-serif;
  text-align: left;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-bottom: 3px;
  line-height: 1.42857143;
  background-size: 9px, 0 3px;
  background-position: 0px calc(50% - 3px), 0% 100%;
  background-repeat: no-repeat, no-repeat;
  background-image: url(/images/arrow-btn-right-black.svg), linear-gradient(to right, #000, #000);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-size: 9px, 100% 3px;
  background-position: 0px calc(50% - 3px), 0% 100%;
  background-repeat: no-repeat, no-repeat;
  background-image: url(/images/arrow-btn-right-black.svg), linear-gradient(to right, #000, #000);
}
#disp.cb-shoppingcart #cb-footnotes {
  float: left;
}
#disp.cb-shoppingcart #cb-footnotes:before {
  float: left;
  display: block;
  margin-top: 10px;
  color: #000;
  width: 100%;
}
body.de #disp.cb-shoppingcart #cb-footnotes:before {
  content: 'Versand Schweiz: \a Der Versand ist gratis und erfolgt noch am selben Tag. \a Der Versand dauert 2 bis 3 Tage. \a Versand übrige Länder: \a Wir bitten unsere Kunden aus dem Ausland die Bücher direkt beim \a tmms-Verlag in Deutschland oder bei Cordee Books in England zu bestellen.';
  white-space: pre-wrap;
}
body.en #disp.cb-shoppingcart #cb-footnotes:before {
  content: 'Shipping Switzerland: \a Shipping is free and same day. \a Shipping takes 2 to 3 days. \a Shipping other countries: \a To our customers from outside Switzerland, we suggest ordering books directly from \a tmms-Publishing in Germany or Cordee Books in UK.';
  white-space: pre-wrap;
}
#disp.cb-shoppingcart .cb-additional-information {
  float: left;
}
#disp.cb-shoppingcart .cb-additional-information:after {
  float: left;
  display: block;
  margin-top: 10px;
  color: #000;
  width: 100%;
}
body.de #disp.cb-shoppingcart .cb-additional-information:after {
  content: 'Spezialpreis für Bergführer und Kletterlehrer (bitte bei «Bemerkungen» angeben).';
}
body.en #disp.cb-shoppingcart .cb-additional-information:after {
  content: 'Special price for mountain guides and climbing instructors (please specify under «Comments»).';
}
#disp.cb-shoppingcart .foot fieldset {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
#disp.cb-shoppingcart .foot fieldset .submit {
  float: left;
}
#disp.mail .foot input {
  float: right;
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 639px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 639px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 639px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 639px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1199px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  margin-top: 20px;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 639px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 639px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: "proxima-nova", sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 639px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 639px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 640px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 640px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  min-height: 40px;
  border-bottom: 1px solid #000;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 640px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile input {
    top: 8px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  background: #FFF;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 640px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
}
@media (max-width: 1199px) {
  .unit.form input.submit {
    margin-top: 15px;
  }
}
@media (max-width: 639px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .unit.form input.submit {
    margin-bottom: 15px;
  }
}
@media (max-width: 639px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #000;
  background-color: #e27c00;
}
.unit.form input.submit:active {
  background-color: #e27c00;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .unit.form div.cb-form-sent {
    margin-top: 15px;
  }
}
@media (max-width: 639px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 1199px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 15px;
  }
}
@media (max-width: 639px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #000;
  background-color: #e27c00;
}
.two-step-verification-container a:active {
  background-color: #e27c00;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr .init i {
  background-color: #d0d4db;
  padding: 2px;
  box-sizing: border-box;
  font-style: normal;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
}
.table td.init {
  padding-left: 0;
  padding-right: 20px;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 639px) {
  .part--table .table {
    font-size: 2.50391236vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  width: 200px;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: left;
  width: 100%;
  margin-top: 45px;
}
@media (max-width: 1199px) {
  #head {
    margin-top: 45px;
  }
}
@media (max-width: 639px) {
  #head {
    margin-top: 30px;
  }
}
#view .scroll-animated {
  transition: opacity 0.6s, transform 0.6s, filter 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 0;
  transform: scale(0.9);
}
#view .scroll-animated.scroll-animated--active {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-size: 16px;
  font-family: "proxima-nova", sans-serif;
  line-height: 1.5;
  color: #000;
  overflow: hidden;
  hyphens: none;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 90vw;
  width: 636px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--beta .desk {
  width: 1080px;
}
.section--explore .desk {
  width: calc(100vw - 80px);
  max-width: 100vw;
}
@media (max-width: 1199px) {
  .section--explore .desk {
    width: 90vw;
    max-width: 90vw;
  }
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--multimood {
  margin-top: 85px;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.onload-animation .section--multimood {
  opacity: 1;
}
.section--explore {
  margin-top: 30px;
}
@media (max-width: 1199px) {
  .section--explore {
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .section--explore {
    margin-top: 20px;
  }
}
.section--explore .mapwrapper {
  display: block;
  float: left;
  width: 100%;
  height: calc(100vh - 195px);
  max-height: 800px;
}
@media (min-width: 1920px) {
  .section--explore .mapwrapper .mystyle {
    border: 10px solid red;
  }
}
@media (max-width: 1199px) {
  .section--explore .mapwrapper {
    height: auto;
  }
}
.section--header {
  position: fixed;
  top: 0;
  background-color: #FFF;
  z-index: 10;
  border-bottom: 1px solid #d0d4db;
}
.section--header .desk {
  width: 1280px;
  max-width: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 75px;
}
#services .cb-cart-icon-holder {
  float: left;
}
#services .cb-cart-icon-holder .meta {
  width: 26px;
  height: 26px;
  background: url(/images/icon-shopcart-black.svg) no-repeat;
  background-position: left bottom;
  background-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 400;
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  position: relative;
}
#services .cb-cart-icon-holder .meta:hover,
#services .cb-cart-icon-holder .meta:focus {
  background: url(/images/icon-shopcart-orange.svg) no-repeat;
  background-position: left bottom;
  background-size: 20px;
}
#services .cb-cart-icon-holder .cb-cart-icon-count {
  position: absolute;
  top: 0;
  right: 0px;
  width: 12px;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  background: #e27c00;
  border: 1px solid #e27c00;
  padding: 2px 1px 1px;
  border-radius: 20px;
  color: #FFF;
  font-weight: bold;
  display: block;
}
#services {
  display: flex;
  float: right;
  position: absolute;
  right: -4px;
  align-items: center;
}
@media (max-width: 1199px) {
  #services {
    bottom: -6px;
  }
}
.mobile-navi-animation #services {
  display: flex;
  position: absolute;
  bottom: unset;
  right: unset;
  left: 24px;
  align-items: center;
  top: 56px;
}
#services .find {
  background-image: url(/images/icon-search-black.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#services .find:hover,
#services .find:focus {
  background-image: url(/images/icon-search-orange.svg);
}
#services .service_email {
  background-image: url(/images/icon-email-black.svg);
  background-repeat: no-repeat;
  background-size: 31px;
  margin-right: 5px;
  width: 31px;
  height: 20px;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#services .service_email:hover,
#services .service_email:focus {
  background-image: url(/images/icon-email-orange.svg);
}
.mobile-navi-animation #services .service_email {
  display: none;
}
.section--grey {
  background-color: #d0d4db;
}
.section--footer {
  background-color: #373736;
}
.section--footer .desk {
  margin-top: 44px;
  margin-bottom: 20px;
}
.cb-layout1 .section--footer {
  margin-top: 5px;
}
.cb-layout4 .section--footer {
  margin-top: 30px;
}
@media (max-width: 1199px) {
  .cb-layout4 .section--footer {
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .cb-layout4 .section--footer {
    margin-top: 20px;
  }
}
#social {
  display: flex;
}
#social .meta {
  display: block;
  width: 35px;
  height: 35px;
  background: no-repeat center;
  background-size: 100% 100%;
  margin-bottom: 35px;
  margin-top: 35px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
@media (max-width: 639px) {
  #social .meta {
    margin-top: 40px;
  }
}
#social .meta.service_instagram {
  background-image: url(/images/instagram.svg);
  margin-right: 5px;
}
#social .meta.service_instagram:hover,
#social .meta.service_instagram:focus {
  background-image: url(/images/instagram-hover.svg);
}
#social .meta.service_facebook {
  background-image: url(/images/facebook.svg);
  margin-left: 5px;
}
#social .meta.service_facebook:hover,
#social .meta.service_facebook:focus {
  background-image: url(/images/facebook-hover.svg);
}
#legal .meta {
  margin: 0 32px;
  text-transform: uppercase;
}
@media (max-width: 639px) {
  #legal .meta {
    font-size: 14px;
    margin: 0 5px;
  }
}
.meta.service_webpartner {
  margin: 15px 0 40px;
  font-size: 14px;
  line-height: 2.14285714;
  padding-right: 170px;
  background-size: auto 28px;
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/dieweberei-webpartner-2022-02-17.png);
}
@media (max-width: 639px) {
  .meta.service_webpartner {
    padding-right: unset;
    padding-bottom: 34px;
    background-position: 50% 100%;
  }
}
.footarea {
  position: relative;
  float: left;
  width: 100%;
}
.footpart {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.1em;
}
.footpart * {
  color: #FFF;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard .fn {
  font-weight: 800;
}
.footpart div.sub1 {
  margin-right: 32px;
}
@media (max-width: 639px) {
  .footpart div.sub1 {
    margin-right: 0;
  }
}
.footpart div.sub1 > .item {
  position: relative;
  float: left;
}
.footpart div.sub1 > .item:after {
  content: '|';
  position: absolute;
  top: 0;
  right: -2px;
}
.footpart div.sub1 > .item.exit:after {
  content: '';
}
.footpart div.sub1 > .item > .menu {
  padding: 0 6px;
  box-sizing: border-box;
}
.footpart div.sub1 > .item > .menu.path {
  color: #e27c00;
}
@media (max-width: 639px) {
  .footpart div.sub1 > .item > .menu {
    font-size: 14px;
  }
}
.toplink {
  float: left;
  margin-left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}
.toplink.cb-goto-top-visible {
  right: 50px;
}
@media (max-width: 1199px) {
  .toplink.cb-goto-top-visible {
    right: 15px;
  }
}
.shopcartside {
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 2000;
  transform: translateY(-50%);
  display: none;
}
.c4 .shopcartside,
.c2 .shopcartside {
  display: block;
}
@media (max-width: 639px) {
  .shopcartside {
    display: none !important;
  }
}
.shopcartside .cb-cart-icon-holder {
  float: left;
}
.shopcartside .cb-cart-icon-link {
  float: left;
  position: relative;
  width: 50px;
  height: 50px;
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-shopcart-black.svg);
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-right: none;
  border-radius: 3px 0 0 3px;
}
.shopcartside .cb-cart-icon-link:hover,
.shopcartside .cb-cart-icon-link:focus {
  background-image: url(/images/icon-shopcart-orange.svg);
}
.shopcartside .cb-cart-icon-count {
  position: absolute;
  left: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 2px 0 0;
  background-color: #e27c00;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.53846154;
  -webkit-font-smoothing: antialiased;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #e27c00;
}
a.open {
  text-decoration: underline;
}
a.open:hover,
a.open:focus {
  text-decoration: none;
}
.part.link.elem .open,
.part.link.doit .open,
.part.link.goto .open,
.part.link.site .open {
  font-weight: 800;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.part.link.elem .open:before,
.part.link.doit .open:before,
.part.link.goto .open:before,
.part.link.site .open:before {
  content: '> ';
}
.part.link.elem .open:after,
.part.link.doit .open:after,
.part.link.goto .open:after,
.part.link.site .open:after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.part.link.elem .open:hover,
.part.link.doit .open:hover,
.part.link.goto .open:hover,
.part.link.site .open:hover,
.part.link.elem .open:focus,
.part.link.doit .open:focus,
.part.link.goto .open:focus,
.part.link.site .open:focus {
  color: #000;
}
.part.link.elem .open:hover:after,
.part.link.doit .open:hover:after,
.part.link.goto .open:hover:after,
.part.link.site .open:hover:after,
.part.link.elem .open:focus:after,
.part.link.doit .open:focus:after,
.part.link.goto .open:focus:after,
.part.link.site .open:focus:after {
  width: 100%;
}
.part.load.file {
  margin-top: 0!important;
}
.part.link.shop .open {
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
  float: left;
  background-color: #e27c00;
}
.part.link.shop .open:hover,
.part.link.shop .open:focus {
  color: #000;
  background-color: #e27c00;
}
.part.link.shop .open:active {
  background-color: #e27c00;
}
.part.link.shop .open:hover,
.part.link.shop .open:focus {
  background-color: #d0d4db;
}
h1 {
  text-align: left;
}
h2 {
  text-align: center;
}
h1,
h2 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: normal;
  letter-spacing: 0.1em;
}
h1 strong,
h2 strong {
  font-weight: 800;
  font-size: 28px;
  line-height: 1.17857143;
}
h1 i,
h2 i {
  font-size: 28px;
  line-height: 1.17857143;
  font-style: normal;
}
h3 {
  font-size: 24px;
  line-height: 1.16666667;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.1em;
}
.cb-layout4 h3 {
  text-align: left;
}
.cb-layout4 .pure h3 {
  letter-spacing: 0.03em;
  font-weight: bold;
}
h3 strong {
  font-weight: 800;
  font-size: 24px;
  line-height: 1.16666667;
}
h4 {
  font-size: 24px;
  line-height: 1.16666667;
  font-weight: normal;
  text-align: left;
  letter-spacing: 0.1em;
  hyphens: auto;
}
h4 strong {
  font-weight: 800;
  font-size: 24px;
  line-height: 1.16666667;
}
.sliderPreTitle strong {
  border-top: 1px solid black;
  display: block;
}
.section--alpha .area,
.section--beta .area {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .section--alpha .area,
  .section--beta .area {
    margin-top: 60px;
  }
}
@media (max-width: 639px) {
  .section--alpha .area,
  .section--beta .area {
    margin-top: 40px;
  }
}
@media (max-width: 1199px) {
  .section--alpha .area,
  .section--beta .area {
    margin-bottom: 60px;
  }
}
@media (max-width: 639px) {
  .section--alpha .area,
  .section--beta .area {
    margin-bottom: 40px;
  }
}
@media (max-width: 639px) {
  .section--alpha .area,
  .section--beta .area {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 639px) and (max-width: 1199px) {
  .section--alpha .area,
  .section--beta .area {
    margin-top: 30px;
  }
}
@media (max-width: 639px) and (max-width: 639px) {
  .section--alpha .area,
  .section--beta .area {
    margin-top: 20px;
  }
}
@media (max-width: 639px) and (max-width: 1199px) {
  .section--alpha .area,
  .section--beta .area {
    margin-bottom: 30px;
  }
}
@media (max-width: 639px) and (max-width: 639px) {
  .section--alpha .area,
  .section--beta .area {
    margin-bottom: 20px;
  }
}
.section--alpha .area.main,
.section--beta .area.main {
  margin-top: 30px;
}
@media (max-width: 1199px) {
  .section--alpha .area.main,
  .section--beta .area.main {
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .section--alpha .area.main,
  .section--beta .area.main {
    margin-top: 20px;
  }
}
.seam .part.pict img:not(.svg) {
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.4);
}
.seam .part.pict a {
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.4);
}
.form .body {
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid black;
}
.cb-layout2 .section--beta .area .seam .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-layout2 .section--beta .area .seam .part {
  text-align: center;
}
.cb-layout2 .section--beta .area .seam .pict.tiny {
  width: 200px;
  margin-left: 10px;
  margin-right: 10px;
}
.cb-layout1 .section--alpha .area .unit.seam + .seam {
  margin-top: -16px;
}
@media (max-width: 639px) {
  .cb-layout1 .section--alpha .area .unit.seam + .seam {
    margin-top: -11px;
  }
}
.section--alpha .area .unit.slim.seam {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  min-height: 370px;
  padding: 20px 0;
  box-sizing: border-box;
}
.section--alpha .area .unit.slim.seam .part {
  width: 62.89308176%;
}
.section--alpha .area .unit.slim.seam .pict.tiny {
  width: 31.44654088%;
  position: absolute;
  margin: 0;
  right: 0;
  top: 30px;
}
.section--alpha .area .unit.wide.seam {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px 20px;
  box-sizing: border-box;
  min-height: 180px;
}
.section--alpha .area .unit.wide.seam + .wide.seam {
  border-top: unset;
  border-bottom: 1px solid black;
  margin-top: 0;
}
.section--alpha .area .unit.wide.seam .part {
  width: 78.6163522%;
}
.section--alpha .area .unit.wide.seam .part.payp {
  width: 62.89308176%;
}
.section--alpha .area .unit.wide.seam .pict.tiny {
  width: 12.10691824%;
  position: absolute;
  margin: 0;
  right: 20px;
  top: 30px;
  margin-bottom: 15px;
}
.unit.fold .ctrl {
  position: relative;
  font-weight: 800;
}
.unit.fold .ctrl .link {
  float: left;
  position: relative;
}
.unit.fold .ctrl .link:after {
  content: '>';
  padding: 0 5px;
  display: inline-block;
  transform: rotate(-90deg);
}
.unit.fold .ctrl .link.more:after {
  transform: rotate(90deg);
}
.unit.fold .ctrl .link:before {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.unit.fold .ctrl .link:hover,
.unit.fold .ctrl .link:focus {
  color: #000;
}
.unit.fold .ctrl .link:hover:before,
.unit.fold .ctrl .link:focus:before {
  width: 100%;
}
#view  .part.pict a .cb-image-container {
  overflow: hidden;
}
#view  .part.pict a .cb-image-container img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  width: 100%;
  object-fit: cover;
  object-position: center;
}
#view  .part.pict a:hover .cb-image-container img {
  transform: scale(1.1);
  opacity: 1;
  width: 100%;
}
.section--alpha .edge {
  z-index: 5;
}
.section--alpha .edge .part.code {
  margin: 0;
}
.cb-layout3 .section--alpha .main .unit:first-child {
  z-index: 5;
}
.cb-layout3 .section--main .cb-image-caption {
  visibility: hidden;
  opacity: 0;
  width: 120px;
  background-color: #000;
  color: #fff;
  font-size: 80%;
  hyphens: none;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 0;
  margin-left: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-layout3 .section--main .cb-image-caption:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 20px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.pikto--container {
  float: left;
  width: 100%;
  position: relative;
}
.pikto {
  position: relative;
  float: left;
  width: 45px;
  margin-right: 18px;
}
@media (max-width: 639px) {
  .pikto {
    width: 40px;
    margin-right: 10px;
  }
}
.pikto .pikto__text {
  visibility: hidden;
  opacity: 0;
  width: 120px;
  background-color: #000;
  color: #fff;
  font-size: 80%;
  hyphens: none;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 0;
  margin-left: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.pikto .pikto__text:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 20px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.cb-image-figure:hover .cb-image-caption {
  visibility: visible;
  opacity: 1;
}
.pikto.inactive .pikto__image {
  opacity: 0.3;
}
.pikto:hover .pikto__text {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1199px) {
  .navigation .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 20px;
  right: 5%;
  z-index: 2001;
  width: 38px;
  height: 24px;
  cursor: pointer;
}
@media (max-width: 639px) {
  .togglenavigation {
    top: 15px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 4px;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  transform: translateX(100px);
  transition: all 1.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transform: translateX(0);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  margin: 100px 0 0;
}
.mobile-navigation div.sub2 > .item {
  padding-left: 25px;
  padding-right: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  transition: background-color 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.sub2 > .item.cb-toggle-target-active {
  background-color: #d0d4db;
}
.mobile-navigation div.sub2 > .item.cb-toggle-target-active > .menu:after {
  width: 100%;
}
.mobile-navigation div.sub2 > .item.exit > .menu {
  color: #e27c00;
}
.mobile-navigation div.sub2 > .item.exit > .menu:after {
  background-color: #e27c00;
}
.mobile-navigation div.sub2 > .item.exit > .menu:hover:after,
.mobile-navigation div.sub2 > .item.exit > .menu:focus:after {
  background-color: #e27c00;
}
.mobile-navigation div.sub2 > .item > .menu {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #000;
  position: relative;
  box-sizing: border-box;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
  display: inline-block;
}
.mobile-navigation div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: #000;
  transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
}
.mobile-navigation div.sub2 > .item > .menu:hover:after,
.mobile-navigation div.sub2 > .item > .menu:focus:after {
  width: 100%;
}
.mobile-navigation div.sub2 > .item > .menu.path:after {
  width: 100%;
}
.mobile-navigation div.sub3 {
  padding-left: 25px;
  box-sizing: border-box;
}
.mobile-navigation div.sub3 > .item {
  width: 100%;
  position: relative;
  margin-bottom: 13px;
  letter-spacing: 0.2em;
  float: left;
  color: #000;
  font-weight: bold;
}
.mobile-navigation div.sub3 > .item.init {
  margin-top: 20px;
}
.mobile-navigation div.sub3 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub3 > .item:hover > .menu,
.mobile-navigation div.sub3 > .item:focus > .menu {
  color: #e27c00;
}
.mobile-navigation div.sub3 > .item > .menu {
  display: inline-block;
  color: #000;
  font-size: 16px;
  line-height: 1.1875;
}
.mobile-navigation div.sub3 > .item > .menu.path {
  color: #e27c00;
}
.mobile-navigation div.sub3 > .item > .cb-toggle {
  display: none;
}
.mobile-navigation div.sub3 > .item > div.sub4 {
  display: none;
}
.mobile-navigation div.sub3 > .item.c3 > .cb-toggle,
.mobile-navigation div.sub3 > .item.c4 > .cb-toggle {
  display: block;
  left: -38px;
  top: 0;
  right: unset;
}
.mobile-navigation div.sub3 > .item.c3 > div.sub4,
.mobile-navigation div.sub3 > .item.c4 > div.sub4 {
  display: block;
}
.mobile-navigation div.sub4 {
  padding-left: 25px;
  box-sizing: border-box;
}
.mobile-navigation div.sub4 > .item {
  width: 100%;
  position: relative;
  margin-bottom: 13px;
  letter-spacing: 0.2em;
  float: none;
  color: #000;
  font-weight: bold;
}
.mobile-navigation div.sub4 > .item > .cb-toggle {
  display: none;
}
.mobile-navigation div.sub4 > .item.init {
  margin-top: 20px;
}
.mobile-navigation div.sub4 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub4 > .item:hover > .menu,
.mobile-navigation div.sub4 > .item:focus > .menu {
  color: #e27c00;
}
.mobile-navigation div.sub4 > .item > .menu {
  display: inline-block;
  color: #000;
  font-size: 16px;
  line-height: 1.1875;
}
.mobile-navigation div.sub4 > .item > .menu.path {
  color: #e27c00;
}
.mobile-navigation div.sub3 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub3 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.sub4 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub4 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: unset;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 5%;
  top: 15px;
  z-index: 2;
  cursor: pointer;
  width: 38px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.part.payp .name {
  float: left;
  width: 100%;
  padding-bottom: 10px;
}
.part.payp.tile .name {
  width: auto;
  padding: 0;
  min-height: 40px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.payp .papc {
  float: left;
  display: flex;
  align-items: flex-start;
}
@media (max-width: 639px) {
  .part.payp .papc {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .part.payp .papc .name {
    order: 1;
    margin-left: 0;
  }
  .part.payp .papc input.numb {
    order: 2;
  }
}
.part.payp input.numb {
  float: left;
  background-color: #FFF;
  border: 1px solid #000;
  padding: 5px 5px 5px 10px;
  min-height: 40px;
  box-sizing: border-box;
  width: 50px;
  text-align: right;
}
.part.payp div.ctrl {
  float: right;
  max-width: calc(100% - 70px);
  display: flex;
  flex-wrap: nowrap;
}
.part.payp.tile div.ctrl {
  max-width: 100%;
}
@media (max-width: 639px) {
  .part.payp.tile div.ctrl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .part.payp.tile div.ctrl .name {
    order: 1;
    margin-left: 0;
  }
  .part.payp.tile div.ctrl input.submit {
    float: right;
    margin-left: 0;
  }
}
@media (max-width: 639px) and (max-width: 1199px) {
  .part.payp.tile div.ctrl {
    margin-top: 15px;
  }
}
@media (max-width: 639px) and (max-width: 639px) {
  .part.payp.tile div.ctrl {
    margin-top: 10px;
  }
}
.part.payp div.ctrl > div {
  float: left;
  min-height: 40px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: bold;
}
.part.payp .prix {
  text-align: right;
  line-height: 1.5;
  padding-left: 3px;
  order: 2;
}
#edit .part.payp .prix {
  width: 70px;
}
.part.payp div.ctrl form {
  float: left;
}
.part.payp div.ctrl form fieldset {
  float: left;
}
.part.payp div.ctrl input.submit {
  float: left;
  margin-left: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: #FFF;
  background-size: 33px 33px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cart.svg);
}
.part.payp div.ctrl input.submit:hover {
  background-image: url(/images/cart-add.svg);
}
.part.payp div.ctrl input.submit.success {
  background-image: url(/images/cart-success.svg);
}
@media (min-width: 1200px) {
  .part.payp div.ctrl input.submit.success:hover {
    background-image: url(/images/cart-add.svg);
  }
}
#disp.cb-shoppingcart table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
#disp.cb-shoppingcart .cury {
  font-size: 10px;
  margin-left: 3px;
}
#disp.cb-shoppingcart tr {
  border-bottom: 1px solid #E0E0E0;
  border-top: 1px solid #E0E0E0;
}
#disp.cb-shoppingcart thead tr {
  border-top: none;
  border-bottom-color: #000;
}
#disp.cb-shoppingcart th,
#disp.cb-shoppingcart td {
  padding: 5px;
  vertical-align: top;
}
#disp.cb-shoppingcart th {
  text-align: left;
}
#disp.cb-shoppingcart .cb-cart-item-count {
  padding-left: 0;
}
#disp.cb-shoppingcart .cb-cart-item-count input.numb {
  width: 54px;
  padding: 0px 2px 0px 5px;
  background: #F0F0F0;
  text-align: right;
  box-sizing: border-box;
  height: 20px;
  min-height: 0;
}
#disp.cb-shoppingcart .cb-cart-value,
#disp.cb-shoppingcart .cb-cart-item-sum,
#disp.cb-shoppingcart .cb-cart-item-price {
  text-align: right;
}
#disp.cb-shoppingcart .cb-cart-item-action {
  padding-right: 0;
}
#disp.cb-shoppingcart .cb-cart-item-action a {
  float: right;
  display: block;
  width: 20px;
  height: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-trash-black.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#disp.cb-shoppingcart .cb-cart-item-action a:hover {
  transform: scale(1.2);
}
#disp.cb-shoppingcart .cb-cart-item-action a:active {
  transform: scale(1);
}
#disp.cb-shoppingcart .cb-cart-subtotals {
  background: #fcfcfc;
}
#disp.cb-shoppingcart .cb-cart-totals {
  background: #f0f0f0;
  font-weight: bold;
}
@media (max-width: 639px) {
  #disp.cb-shoppingcart table {
    float: left;
    width: 100%;
    display: block;
  }
  #disp.cb-shoppingcart table * {
    float: left;
    display: block;
  }
  #disp.cb-shoppingcart thead {
    display: none;
  }
  #disp.cb-shoppingcart tbody,
  #disp.cb-shoppingcart tfoot {
    width: 100%;
  }
  #disp.cb-shoppingcart tr {
    width: 100%;
    padding: 10px 0;
    margin-bottom: -1px;
  }
  #disp.cb-shoppingcart td {
    width: 100%;
    position: relative;
    padding: 3px 0 3px 100px !important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  #disp.cb-shoppingcart td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    font-weight: bold;
  }
  #disp.cb-shoppingcart .cb-cart-subtotals td,
  #disp.cb-shoppingcart .cb-cart-totals td {
    padding: 0 !important;
  }
  #disp.cb-shoppingcart .cb-cart-subtotals tr,
  #disp.cb-shoppingcart .cb-cart-totals tr {
    padding: 3px 6px;
    box-sizing: border-box;
  }
}
#disp.cb-shoppingcart .cb-address,
#disp.cb-shoppingcart .cb-additional-information,
#disp.cb-shoppingcart .cb-payment,
#disp.cb-shoppingcart .cb-agb,
#disp.cb-shoppingcart .cb-order {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#disp.cb-shoppingcart .cb-address:first-child h3:first-child {
  padding-top: 0;
}
.cb-address p {
  display: block;
  width: 100%;
}
.cb-address input.submit {
  float: left;
  margin-top: 20px;
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
  line-height: 1.42857143;
}
.cb-address input.submit:hover,
.cb-address input.submit:focus {
  color: #000;
  background-color: #e27c00;
}
.cb-address input.submit:active {
  background-color: #e27c00;
}
#disp.cb-shoppingcart a.agbs {
  float: left;
  margin-bottom: 20px;
  background-color: #d0d4db;
  line-height: 1.5;
  cursor: pointer;
  color: #000;
  font-family: 'FrutigerBold', sans-serif;
  padding: 7px 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #000;
  float: right;
  line-height: 1.42857143;
}
#disp.cb-shoppingcart a.agbs:hover,
#disp.cb-shoppingcart a.agbs:focus {
  color: #000;
  background-color: #e27c00;
}
#disp.cb-shoppingcart a.agbs:active {
  background-color: #e27c00;
}
.section--beta .area .unit.slim .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section--beta .area .unit.slim .part.tiny {
  width: calc(50% - 10px);
}
.section--slider .desk {
  width: 656px;
  max-width: 656px;
}
@media (max-width: 639px) {
  .section--slider .desk {
    max-width: 90vw;
  }
  #view .section--slider .desk {
    max-width: 100vw;
  }
}
.section--slider.slider--region .desk {
  width: 1080px;
  max-width: 90vw;
}
#view .section--slider.slider--region .desk {
  max-width: 90vw;
}
@media (max-width: 639px) {
  .section--slider.slider--region .desk {
    max-width: 90vw;
  }
}
.js-slider {
  float: left;
  width: 100%;
  margin-bottom: 60px;
  position: relative;
}
@media (max-width: 1199px) {
  .js-slider {
    margin-bottom: 60px;
  }
}
@media (max-width: 639px) {
  .js-slider {
    margin-bottom: 40px;
  }
}
@media (max-width: 1199px) {
  .js-slider {
    margin-bottom: 90px;
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  .js-slider {
    margin-bottom: 90px;
  }
}
@media (max-width: 1199px) and (max-width: 639px) {
  .js-slider {
    margin-bottom: 60px;
  }
}
@media (max-width: 639px) {
  .js-slider {
    margin-bottom: 120px;
  }
}
@media (max-width: 639px) and (max-width: 1199px) {
  .js-slider {
    margin-bottom: 120px;
  }
}
@media (max-width: 639px) and (max-width: 639px) {
  .js-slider {
    margin-bottom: 80px;
  }
}
.js-slider__width {
  float: left;
  width: 100%;
}
#view .js-slider__width {
  overflow: hidden;
}
#view .section--slider .area {
  display: flex;
  justify-content: center;
}
#edit .section--slider .area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#view .section--slider {
  margin-top: -55px;
}
@media (max-width: 1199px) {
  #view .section--slider {
    margin-top: -30px;
  }
}
.section--slider .area .unit {
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 310px;
}
@media (max-width: 1199px) {
  .section--slider .area .unit {
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .section--slider .area .unit {
    margin-top: 20px;
  }
}
@media (max-width: 1199px) {
  .section--slider .area .unit {
    margin-bottom: 30px;
  }
}
@media (max-width: 639px) {
  .section--slider .area .unit {
    margin-bottom: 20px;
  }
}
.section--slider .area .unit .body {
  box-sizing: border-box;
  min-height: 310px;
  display: flex;
  flex-direction: column;
}
#view .section--slider .area .unit {
  margin: 0 10px;
  flex-shrink: 0;
  transition: all 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--slider.slider--news .area .unit {
  min-height: 310px;
}
.section--slider.slider--news .area .unit .body {
  min-height: 310px;
}
.section--slider.slider--news .area .unit .part {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
#view .section--slider.slider--news .area .unit {
  width: 636px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#view .section--slider.slider--news .area .unit .body {
  width: 636px;
}
#view .section--slider.slider--news .area .unit.unit--collapsed {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}
.section--slider.slider--guide .area .unit {
  min-height: 310px;
  width: 192px;
}
.section--slider.slider--guide .area .unit .body {
  min-height: 310px;
}
#view .section--slider.slider--guide .area .unit {
  width: 200px;
}
#view .section--slider.slider--guide .area .unit .body {
  width: 200px;
}
#view .section--slider.slider--guide .area .unit.unit--collapsed {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}
.section--slider.slider--region .area .unit {
  min-height: 0px;
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.section--slider.slider--region .area .unit .body {
  min-height: 0px;
}
.section--slider.slider--region .area .unit .part {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
}
.section--slider.slider--region .area .unit .part.pict .cb-image-container {
  height: auto;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
}
.section--slider.slider--region .area .unit .part.pict .cb-image-container img {
  display: block;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
#view .section--slider.slider--region .area .unit {
  width: 340px;
}
#view .section--slider.slider--region .area .unit .body {
  width: 340px;
}
#view .section--slider.slider--region .area .unit.unit--collapsed {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}
.js-slider__link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 32px;
  height: 32px;
  background-size: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-arrow-left-black.svg);
  cursor: pointer;
}
.no-slider .js-slider__link {
  display: none;
}
.js-slider__link:hover,
.js-slider__link:focus {
  background-image: url(/images/icon-arrow-left-orange.svg);
}
#edit .js-slider__link {
  display: none;
}
@media (max-width: 1199px) {
  .js-slider__link {
    top: unset;
    bottom: -60px;
  }
  .slider--news .js-slider__link {
    bottom: -60px;
  }
}
.js-slider__link--prev {
  left: -50px;
}
@media (max-width: 1199px) {
  .js-slider__link--prev {
    left: calc(50% - 40px);
  }
}
.js-slider__link--next {
  right: -50px;
  background-image: url(/images/icon-arrow-right-black.svg);
}
.js-slider__link--next:hover,
.js-slider__link--next:focus {
  background-image: url(/images/icon-arrow-right-orange.svg);
}
@media (max-width: 1199px) {
  .js-slider__link--next {
    right: calc(50% - 40px);
  }
}
@media (max-width: 1199px) {
  .section--slider .area .unit {
    min-height: 460px;
  }
  .section--slider .area .unit .body {
    min-height: 460px;
  }
  .section--slider .area .unit .part {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #view .section--slider .area .unit {
    margin: 0 10px;
    width: 580px;
  }
  #view .section--slider .area .unit .body {
    width: 580px;
  }
  .section--slider.slider--news .area .unit {
    min-height: 310px;
  }
  .section--slider.slider--news .area .unit .body {
    min-height: 310px;
  }
  .section--slider.slider--region .area .unit {
    min-height: 0px;
    width: calc(30vw - 20px);
  }
  .section--slider.slider--region .area .unit .body {
    min-height: 0px;
    width: calc(30vw - 20px);
  }
  #view .section--slider.slider--region .area .unit {
    width: calc(30vw - 20px);
  }
  #view .section--slider.slider--region .area .unit .body {
    width: calc(30vw - 20px);
  }
  #view .section--slider.slider--region .area .unit.unit--collapsed {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 639px) {
  .section--slider .area .unit {
    min-height: 0;
  }
  .section--slider .area .unit .body {
    min-height: 0;
    width: 90vw;
  }
  #view .section--slider .area .unit {
    margin: 0 10px;
    width: 331px;
  }
  #view .section--slider .area .unit .body {
    width: 331px;
  }
  #view .section--slider.slider--news .area .unit {
    width: 90vw;
  }
  #view .section--slider.slider--news .area .unit .body {
    width: 90vw;
  }
  #view .section--slider.slider--news .area .unit.unit--collapsed {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .section--slider.slider--guide .area .unit {
    min-height: 0;
    width: calc(45vw - 10px);
  }
  .section--slider.slider--guide .area .unit .body {
    min-height: 0;
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--guide .area .unit {
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--guide .area .unit .body {
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--guide .area .unit.unit--collapsed {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .section--slider.slider--region .area .unit {
    min-height: 0px;
    width: calc(45vw - 10px);
  }
  .section--slider.slider--region .area .unit .body {
    min-height: 0px;
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--region .area .unit {
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--region .area .unit .body {
    width: calc(45vw - 10px);
  }
  #view .section--slider.slider--region .area .unit.unit--collapsed {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
/*# sourceMappingURL=./screen-small.css.map */